@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap');

/* =========================
   Color Variables
========================= */

/* Light Mode Colors */
:root {
  --color-primary-light: #3498db;
  --color-secondary-light: #2ecc71;
  --color-background-light: #f0f2f5;
  --color-text-light: #ffffff;
  --color-accent-light: #e74c3c;
  --color-card-bg-light: #ffffff;
  --color-card-border-light: rgba(0, 0, 0, 0.1);

  /* Dark Mode Colors */
  --color-primary-dark: #61dafb;
  --color-secondary-dark: #4ade80;
  --color-background-dark: #1f2937;
  --color-text-dark: #e2e8f0;
  --color-accent-dark: #f87171;
  --color-card-bg-dark: #374151;
  --color-card-border-dark: rgba(255, 255, 255, 0.1);

  /* Default Colors (Dark Mode) */
  --color-primary: var(--color-primary-dark);
  --color-secondary: var(--color-secondary-dark);
  --color-background: var(--color-background-dark);
  --color-text: var(--color-text-dark);
  --color-accent: var(--color-accent-dark);
  --color-card-bg: var(--color-card-bg-dark);
  --color-card-border: var(--color-card-border-dark);

  /* Additional Colors */
  --color-shadow: rgba(0, 0, 0, 0.2);
  --color-overlay: rgba(0, 0, 0, 0.5);
}

/* RGB Values for Transparency */
:root {
  --rgb-primary: 97, 218, 251;
  --rgb-secondary: 74, 222, 128;
  --rgb-accent: 248, 113, 113;
  --rgb-card-bg: 55, 65, 81;
  --rgb-shadow: 0, 0, 0;
}

/* =========================
   Typography
========================= */

:root {
  /* Font Sizes */
  --font-size-small: 0.875rem;
  --font-size-medium: 1rem;
  --font-size-large: 1.25rem;
  --font-size-xlarge: 1.5rem;

  /* Spacing */
  --spacing-small: 0.5rem;
  --spacing-medium: 1rem;
  --spacing-large: 1.5rem;
  --spacing-xlarge: 2rem;

  /* Border Radius */
  --border-radius-small: 4px;
  --border-radius-medium: 8px;
  --border-radius-large: 12px;

  /* Transition */
  --transition: all 0.3s ease;
}

/* =========================
   Theme Modes
========================= */

/* Light Mode */
@media (prefers-color-scheme: light) {
  :root {
    --color-primary: var(--color-primary-light);
    --color-secondary: var(--color-secondary-light);
    --color-background: var(--color-background-light);
    --color-text: var(--color-text-light);
    --color-accent: var(--color-accent-light);
    --color-card-bg: var(--color-card-bg-light);
    --color-card-border: var(--color-card-border-light);
  }
}

/* Dark Mode Toggle */
body.dark-mode {
  --color-primary: var(--color-primary-dark);
  --color-secondary: var(--color-secondary-dark);
  --color-background: var(--color-background-dark);
  --color-text: var(--color-text-dark);
  --color-accent: var(--color-accent-dark);
  --color-card-bg: var(--color-card-bg-dark);
  --color-card-border: var(--color-card-border-dark);
}

/* =========================
   Global Styles
========================= */

body {
  background: linear-gradient(135deg, var(--color-background) 0%, var(--color-card-bg) 100%);
  color: var(--color-text);
  transition: var(--transition);
  font-size: var(--font-size-medium);
  line-height: 1.6;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--color-primary);
  margin: var(--spacing-medium) 0;
}

/* =========================
   Components
========================= */

/* Card Component */
.card, .leaderboard {
  background-color: var(--color-card-bg);
  border: 1px solid var(--color-card-border);
  box-shadow: 0 2px 4px var(--color-shadow);
  transition: var(--transition);
  border-radius: var(--border-radius-medium);
  padding: var(--spacing-medium);
}

.card:hover, .leaderboard:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px var(--color-shadow);
}

/* Text Elements */
.card-title, .leaderboard-title {
  color: var(--color-text);
}

.percentage-container {
  background-color: var(--color-overlay);
  border-radius: var(--border-radius-small);
}

.percentage-fill {
  background-color: var(--color-accent);
}

/* Buttons */
.button {
  background: var(--color-primary);
  color: var(--color-background);
  transition: var(--transition);
  border-radius: var(--border-radius-small);
  padding: var(--spacing-small) var(--spacing-medium);
  border: none;
  cursor: pointer;
}

.button:hover {
  background: var(--color-secondary);
  box-shadow: 0 0 10px var(--color-accent);
}

/* Leaderboard Specific */
.leaderboard-rank {
  color: var(--color-accent);
}

.leaderboard-image {
  border: 2px solid var(--color-primary);
  border-radius: 50%;
}

.leaderboard-votes {
  color: var(--color-primary);
}

/* Winner Styles */
.winner-name {
  color: var(--color-primary);
}

.winner-details {
  color: var(--color-text);
}

/* =========================
   Utilities
========================= */

/* Custom Scrollbar */
::-webkit-scrollbar-track {
  background: var(--color-background);
}

::-webkit-scrollbar-thumb {
  background: var(--color-primary);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-secondary);
}

/* Focus Styles for Accessibility */
:focus {
  outline: 2px solid var(--color-accent);
  outline-offset: 2px;
}

/* Toast Notifications */
.toast {
  background-color: #4caf50;  /* Success green */
  color: #ffffff;
  box-shadow: 0 2px 10px rgba(var(--rgb-shadow), 0.1);
}

.toast-error {
  background-color: #f44336;  /* Error red */
}

/* =========================
   Backgrounds & Animations
========================= */

/* Animated Lava Lamp Background */
@keyframes lavaLamp {
  0%, 100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}

.lava-lamp-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 300% 300%;
  animation: lavaLamp 20s ease infinite;
  z-index: -1;
  filter: blur(40px);
}

/* Dark Mode Lava Lamp */
body.dark-mode .lava-lamp-background {
  background: linear-gradient(
    45deg,
    #ff6b6b,
    #4ecdc4,
    #45b7d1,
    #f7797d
  );
}

/* Light Mode Lava Lamp */
body:not(.dark-mode) .lava-lamp-background {
  background: linear-gradient(
    45deg,
    #ff9a9e,
    #fad0c4,
    #ffecd2,
    #fcb69f
  );
}

/* Fun Fact Container */
.fun-fact-container {
  background-color: rgba(var(--rgb-card-bg), 0.6);
  border-radius: var(--border-radius-medium);
  padding: var(--spacing-medium);
  box-shadow: 0 2px 10px rgba(var(--rgb-shadow), 0.1);
  transition: var(--transition);
}

.fun-fact-container:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(var(--rgb-shadow), 0.2);
}

.fun-fact-label {
  color: var(--color-primary);
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: var(--spacing-small);
}

.fun-fact-text {
  color: var(--color-text);
  font-style: italic;
  line-height: 1.6;
  position: relative;
  padding: 0 var(--spacing-medium);
}

.fun-fact-text::before,
.fun-fact-text::after {
  content: '"';
  font-size: 1.5em;
  color: var(--color-accent);
  opacity: 0.5;
  position: absolute;
}

.fun-fact-text::before {
  left: 0;
  top: -5px;
}

.fun-fact-text::after {
  right: 0;
  bottom: -15px;
}

/* Particle Background */
#particles-js {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -2; /* Adjusted to be behind lava lamp */
}
